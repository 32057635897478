import Projects from "../components/Projects"
import Skills from "../components/Skills"

export default function ProjectsSkills() {
    return(
        <div>
            <Projects />
            <Skills />
        </div>
    )
}